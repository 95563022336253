import React, { Component } from 'react';

export class About extends Component {
  static displayName = About.name;

  constructor(props) {
    super(props);
    this.state = { currentCount: 0 };
    this.incrementCounter = this.incrementCounter.bind(this);
  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1
    });
  }

  render() {
    return (
        <div className="page-content-container p-5">
            <div>
                <h4>Par mums</h4>
                <p>
                    Tūrisma aģentūrā AlfaTours mēs aizrautīgi tiecamies palīdzēt cilvēkiem plānot un piedzīvot
                    aizraujošus ceļojumus.
                </p>

                <p>
                    Mūsu komanda vienmēr ir gatava palīdzēt ar plānošanu un organizēšanu gan individuāliem ceļotājiem,
                    gan grupām, piemēram, ģimenēm, draugiem, vai darba komandām. Mēs vienmēr ar lielāko prieku sniedzam
                    padomus par vēlamo ceļojuma ilgumu, aktivitātēm un piemeklējam izdevīgākos piedāvājumus gan
                    lidojumiem, gan viesnīcu izvēlei, gan ceļojumiem ar autobusu. Piedāvājam arī ceļojumu apdrošināšanu.
                </p>

                <p>
                    Mēs esam pieredzējušu ceļojumu ekspertu komanda, kuras mērķis ir sniegt personalizētu un teicamu
                    apkalpošanu ikvienam mūsu klientam.
                </p>
                <h5>
                    Paldies, ka izvēlējāties ceļojumu aģentūru Alfa Tours savā nākamajā atvaļinājumā. Mēs ceram jums
                    palīdzēt radīt atmiņas, kas paliks mūža garumā.
                </h5>
            </div>

            <div className="row mt-4 ml-1 border rounded">
                <div className="row rounded">
                    <img className="col-12 col-md-5 card-img-top overflow-hidden img overflow-hidden "
                         style={{maxHeight: "320px"}} src="/images/ina.jpg"
                         alt="Ina Tērauda - Alfatours dibinātāja un vadītāja"/>
                    <div className="card-body col-12 col-md-7 col-sm-12">
                        <h5 className="card-title mb-0">Ina Tērauda </h5>
                        <small className="text-muted mt-0">Alfatours dibinātāja un vadītāja</small>
                        <p className="card-text">
                            <p className="mt-1">
                                Inai ir vaiāk kā 20 gadu pieredze strādājot tūrisma jomā. Viņa ir ļoti daudz ceļojusi un
                                turpina to joprojām darīt, lai pilnveidotu savu pieredzi ar dažādu valstu un kultūru
                                iepazīšanu un, lai varētu sniegt vairāk ieskatu un informācijas citiem ceļotājiem. Ar
                                lielāko prieku vienmēr ieteiks kādas praktiskas lietas jāņem vērā izvēloties ceļojumu.
                            </p>
                        </p>
                    </div>
                </div>
            </div>


            <h4 className="mt-4">Kontakti</h4>

            <b>Alfa Tours SIA</b>
            <p>Adrese: <b>Tukuma nov., Tukums, Kuldīgas iela 70 - 52, LV-3104</b></p>
            <p>Reģistrācijas numurs: <b> 40203469064</b></p>
            <p>Licences numurs: <b>T-2023-19</b></p>
            <p>Swedbank AS - <b>LV29HABA0551041151357</b></p>
            
            <hr/>
            <p>Ina Tērauda</p>
            <p>Tālrunis +371 266 439 33</p>
            
            
            <h4 className="mt-4">Mūsu birojs</h4>
            <p className="mb-0">Tukums, Brīvības laukums 18</p>
            <small className="mt-0">(Tikšanās birojā tikai pēc iepriekšējas saskaņošanas telefoniski vai pa
                e-pastu)</small>

        </div>
    );
  }
}
