import React, { Component } from 'react';
import IframeComponent from "./IframeComponent";

export class Home extends Component {
  static displayName = Home.name;

  
  render () {
    return (<div>
      {/*<script src="https://www.novatours.lv/js/remote/iframe.js"></script>*/}
      {/*create container*/}
      <div className="container mt-100">
        
      
      
          <div className="App">
            <IframeComponent />
          </div>
            {/*<iframe src={this.getUrl()} class="nv-iframe" id="frame-container" scrolling="no"></iframe>*/}
      </div>
      </div>)
  }
}
