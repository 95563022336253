import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
      <header>
        
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
         
          <Container>
           
            <NavbarBrand tag={Link} to="/"><img src="/logo.png" width="200" /></NavbarBrand>
            
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            
            <div className="ml-0 p-0 row d-none d-sm-inline d-md-none">
              <div className="col-12">
                <a className="ml-4" href="tel:+371 26 643 933">+371 26 643 933</a>
              </div>
            </div>
            
            <div className="ml-4 p-0 row d-none d-md-inline">
              <div className="col-12">
                Tel.:    <a className="ml-4" href="tel:+371 26 643 933">+371 26 643 933</a>
              </div>
              
              <div className="col-12 ml-0">
                E-pasts: <a href="mailto:info@alfatours.lv">info@alfatours.lv</a>
              </div>             
            </div>           
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>

              <div className="ml-0 mt-2 p-0 row d-sm-none">
                <div className="col-12 p-0">
                  Tel.:    <a className="ml-4" href="tel:+371 26 643 933">+371 26 643 933</a>
                </div>

                <div className="col-12 p-0">
                  E-pasts: <a href="mailto:info@alfatours.lv">info@alfatours.lv</a>
                </div>
              </div>
              
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Ceļojumi</NavLink>
                </NavItem>
                {/*<NavItem>*/}
                {/*  <NavLink tag={Link} className="text-dark" to="/avio-biletes">Lidojumi</NavLink>*/}
                {/*</NavItem>*/}
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/par-mums">Par Mums</NavLink>
                </NavItem> 
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
