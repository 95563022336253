import React, { useEffect, useState } from 'react';

const IframeComponent = () => {
    const [config, setConfig] = useState({});

    useEffect(() => {
        fetch('/config') // Fetch the config from the API.
            .then(res => res.json())
            .then(data => {
                setConfig(data);
                
                window.nova_iframe_options = data;
                var nova_iframe_options = data;    
                
                console.log(data);
                                
                (function(d, t, o, u){
                    var e = d.createElement(t),
                    s = d.getElementsByTagName(t)[0];
                    e.src = u;
                    s.parentNode.insertBefore(e, s);
                }) (document, 'script', nova_iframe_options,
                    'https://www.novaturas.lt/static/js/iframe.js');
                
            });
    }, []);

    return (
        <div className="pt-4 mb-3 bg-white rounded-sm">
            <div id="novatoursiframe" className="clear"
                 style={{background: "url('https://www.novaturas.lt/iframe_site2/public/images/load.gif') center center no-repeat"}}></div>
        </div>

    );
}

export default IframeComponent;