import React, { useEffect } from 'react';
import { Route, Router,  } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { About } from './components/About';
import { createBrowserHistory } from 'history';
import { useLocation } from "react-router-dom";
import './custom.css'
import Avio from "./components/Avio";
import ReactGA from "react-ga";

export default (props)=> {

    const location = useLocation();

    useEffect(() => {
        ReactGA.ga('send', 'pageview', location.pathname + location.search);
    }, [location]);

    return (
      <Layout>
              <Route exact path='/' component={Home}  />
              <Route path='/par-mums' component={About} />
              <Route path='/avio-biletes' component={Avio} />
        {/*<AuthorizeRoute path='/fetch-data' component={FetchData} />*/}
        {/*<Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />*/}
      </Layout>
    );
  
}
