import React, {Component, useEffect} from 'react';

export default ()=>{
    
    useEffect(()=>{
        var loadScript = function (src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            var body = document.getElementsByTagName('body')[0];
            body.appendChild(tag);
        }
        loadScript("https://widgets.skyscanner.net/widget-server/js/loader.js");

    },[]);
    return (<>
       
        <div className="page-content-container p-5">
            <h4>Lidojumu meklēšana</h4>
            <div data-skyscanner-widget="SearchWidget" data-currency="EUR" data-locale="en-EN" data-origin-name="Rīga" data-button-label="Meklēt" data-hide-powered-by="true"></div>
            {/*<script src="https://widgets.skyscanner.net/widget-server/js/loader.js" async></script>*/}
        </div>
    </>)
}