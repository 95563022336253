import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { MessengerChat } from "react-messenger-chat-plugin";

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
    <div className="page-content-warp">
      <div className="page-content">
        <NavMenu />
       
        <Container>
          {this.props.children}         
        </Container>
        <MessengerChat
            pageId="276949382370299"
            language="lv_LV"           
            debugMode={false}
            loggedInGreeting={"Sveiki! Kā mēs varam palīdzēt?"}
            loggedOutGreeting={"Sveiki! Kā mēs varam palīdzēt?"}
            greetingDialogDisplay="show"
            greetingDialogDelay={3000}
            themeColor={"#0084ff"}
             />
      </div>
    </div>
    );
  }
}
